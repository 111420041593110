<script setup lang="ts">
const emit = defineEmits<{
  mouseenter: [item?: PostItem];
  mouseleave: [item?: PostItem];
}>();

const props = withDefaults(
  defineProps<{
    items: (GridItem | "loading")[];
    container?: boolean;
    mobileContainer?: boolean;
    title?: string;
    type?: PostType;
    loading?: boolean;
    withMap?: boolean;
  }>(),
  {
    items: () => [],
    container: false,
    mobileContainer: false,
  }
);

const { getPostTypeTitlePlural } = usePostTypes();

const items = computed(() => {
  if (props.items.length === 0 && props.loading) {
    return Array.from<"loading">({ length: 8 }).map(() => "loading");
  } else {
    return props.items;
  }
});

const mounted = ref(false);

onMounted(() => {
  mounted.value = true;
});
</script>

<template>
  <section class="post-grid">
    <div
      class="post-grid__container"
      :class="[
        container ? 'container' : mobileContainer ? 'max-lg:container' : '',
      ]"
    >
      <div class="post-grid__header sr-only">
        <h2 class="post-grid__title">
          {{ title || (type ? getPostTypeTitlePlural(type) : "Wpisy") }}
        </h2>
      </div>

      <div
        v-if="items.length > 0 || loading"
        class="post-grid__wrapper grid gap-10"
        :class="[withMap ? 'lg:grid-cols-2' : 'lg:grid-cols-4']"
      >
        <div
          v-for="(item, index) in items"
          class="post-grid__item min-w-0 min-h-0 w-full"
          :key="
            item === 'loading'
              ? `loading-${index}-wrapper`
              : `item-${item?.post?.id || item?.id || index}-wrapper`
          "
        >
          <PostGridLoader v-if="item === 'loading'" :key="`loading-${index}`" />

          <PostGridItem
            v-else-if="item?.post"
            :key="`item-${item?.post?.id || item?.id || index}`"
            :item="item"
            :lazy="index >= 4"
            title-tag="h3"
            @mouseenter="() => $nextTick(() => emit('mouseenter', item.post))"
            @mouseleave="() => emit('mouseleave', item.post)"
          />
        </div>
      </div>

      <div v-else class="post-grid__empty lg:col-span-4 my-[5.25rem]">
        <p
          class="post-grid__empty-text flex flex-col items-center justify-center gap-4 lg:block text-center text-lg leading-8 font-semibold"
        >
          <span>Nie znaleziono szukanego wyniku</span>
        </p>
      </div>
    </div>
  </section>
</template>
